import React, { useEffect, useState } from "react";
import { MDBBtn } from "mdb-react-ui-kit";
import { Link, useParams } from "react-router-dom";

export default function View() {
  const [logo, setLogo] = useState("");
  const [name, setName] = useState("");
  const [data, setData] = useState([]);
  const [whatsapp, setWhatsapp] = useState("");
  const [customlink, setCustomlink] = useState("");
  const [adname, setAdname] = useState("");

  const { admin } = useParams();
  const { code } = useParams();

  useEffect(() => {
    document.body.style.backgroundColor = "black";
    Getlogo(admin);
    GetData(admin, code);
    GetWhatsapp(admin);
    GetcustomLink(admin);
    GetName(admin);
    console.log(admin);
  }, []);

  const Getlogo = async (id) => {
    await fetch(`https://api.bestselalu.com/adminlogo?adminId=${id}`, {
      method: "GET",
      headers: {
        "api-key": process.env.REACT_APP_API_KEY,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Request failed.");
        }
        return response.json();
      })
      .then((data) => {
        setLogo(data.logo);
        setName(data.name);
        setAdname(data.adname);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const GetcustomLink = async (id) => {
    await fetch(`https://api.bestselalu.com/getcustomlink?adminId=${id}`, {
      method: "GET",
      headers: {
        "api-key": process.env.REACT_APP_API_KEY,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Request failed.");
        }
        return response.json();
      })
      .then((data) => {
        setCustomlink(data.link);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const GetWhatsapp = async (id) => {
    await fetch(`https://api.bestselalu.com/getWhatsapp?uuid=${id}`, {
      method: "GET",
      headers: {
        "api-key": process.env.REACT_APP_API_KEY,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Request failed.");
        }
        return response.json();
      })
      .then((data) => {
        if (data.whatsapp == "notfound") {
          setWhatsapp("no");
        } else {
          setWhatsapp(data.whatsapp);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const GetData = async (adminId, codeId) => {
    await fetch(
      `https://api.bestselalu.com/getdata?adminId=${adminId}&codeId=${codeId}`,
      {
        method: "GET",
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Request failed.");
        }
        return response.json();
      })
      .then((data) => {
        setData(data.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const GetName = async (uid) => {
    await fetch(`https://api.bestselalu.com/getName?uid=${uid}`, {
      method: "GET",
      headers: {
        "api-key": process.env.REACT_APP_API_KEY,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Request failed.");
        }
        return response.json();
      })
      .then((data) => {
        setName(data.name);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  };

  return (
    <div className="userpage">
      <div>
        <center>
          {data.map((item, index) => (
            <p style={{ color: "white", fontSize: "18px", marginTop: "15px" }}>
              {new Date(item.Dated).toLocaleString(undefined, options)}
            </p>
          ))}
          <img
            src={`https://api.bestselalu.com/images/${logo}`}
            alt=""
            style={{ width: "300px", height: "200px" }}
          />
          <h4
            style={{
              color: "white",
            }}
          >
            {name}
          </h4>
          <h4
            style={{
              color: "white",
              border: "3px solid white",
              width: "300px",
              textAlign: "center",
              padding: "5px",
              fontWeight: "bold",
            }}
          >
            {adname}
          </h4>
          {data.map((item, index) => (
            <div style={{ marginTop: "10px" }}>
              <h5 style={{ color: "white" }}>ANGKA MAIN</h5>
              <p style={{ color: "yellow", fontSize: "18px" }}>{item.number}</p>
              <h5 style={{ color: "white" }}>TOP 4D (BB)</h5>
              <p style={{ color: "yellow", fontSize: "18px" }}>{item.fourD}</p>
              <h5 style={{ color: "white" }}>TOP 3D (BB)</h5>
              <p style={{ color: "yellow", fontSize: "18px" }}>{item.threeD}</p>
              <h5 style={{ color: "white" }}>TOP 2D (BB)</h5>
              <p style={{ color: "yellow", fontSize: "18px" }}>{item.twoD}</p>
              <h5 style={{ color: "white" }}>COLOK BEBAS</h5>
              <p style={{ color: "yellow", fontSize: "18px" }}>
                {item.colorbean}
              </p>
              <h5 style={{ color: "white" }}>COLOK 2D</h5>
              <p style={{ color: "yellow", fontSize: "18px" }}>
                {item.colok2D}
              </p>
              <h5 style={{ color: "white" }}>SHIO JITU</h5>
              <p style={{ color: "yellow", fontSize: "18px" }}>
                {item.shioJitu}
              </p>
              <h5 style={{ color: "white" }}>DESKRIPSI</h5>
              <p
                style={{
                  color: "yellow",
                  fontSize: "18px",
                  paddingBottom: "90px",
                }}
              >
                {item.description}
              </p>
            </div>
          ))}
        </center>
      </div>
      <div
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          zIndex: 9999,
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
          backgroundColor: "#404040",
        }}
      >
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/WhatsApp_icon.png/598px-WhatsApp_icon.png"
          alt="WhatsApp Logo"
          style={{ width: "70px", cursor: "pointer" }}
          onClick={() => {
            if (whatsapp == "no") {
              alert("No WhatsApp added");
            } else {
              window.open(`https://wa.me/${whatsapp}`, "_blank");
            }
          }}
        />
        <a href={customlink == "" ? "https://besttogel.org/" : customlink}>
          <MDBBtn
            style={{
              backgroundColor: "#D3D3D3",
              color: "black",
              border: "3px solid white",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            DAFTAR
          </MDBBtn>
        </a>
      </div>
    </div>
  );
}
