import React, { useEffect, useState } from "react";
import { Input } from "mdbreact";
import { MDBBtn, MDBSpinner } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

export default function Main() {
  const [logo, setLogo] = useState("");
  const [number, setNumber] = useState("");
  const [uid, setUid] = useState("");
  const [load, setLoad] = useState(false);
  const [desload, setDesLoad] = useState(false);
  const [isdone, setIsdone] = useState(false);
  const [date, setDate] = useState("");
  const [codeId, setCodeId] = useState();
  const [data, setData] = useState({});
  const [name, setName] = useState("");

  useEffect(() => {
    const sesh = Cookies.get("sesh_uid");
    if (sesh != null) {
      const cleanedHex = sesh.replace(/\s/g, "");
      const hexArray = new Uint8Array(
        cleanedHex.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
      );
      const decoder = new TextDecoder("utf-8");
      Getlogo(decoder.decode(hexArray));
      GetName(decoder.decode(hexArray));
      setUid(decoder.decode(hexArray));
    }
  }, []);

  const Getlogo = async (uid) => {
    await fetch(`https://api.bestselalu.com/getlogo?uid=${uid}`, {
      method: "GET",
      headers: {
        "api-key": process.env.REACT_APP_API_KEY,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Request failed.");
        }
        return response.json();
      })
      .then((data) => {
        setLogo(data.logo);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const GetName = async (uid) => {
    await fetch(`https://api.bestselalu.com/getName?uid=${uid}`, {
      method: "GET",
      headers: {
        "api-key": process.env.REACT_APP_API_KEY,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Request failed.");
        }
        return response.json();
      })
      .then((data) => {
        setName(data.name);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const Generate = async (event) => {
    event.preventDefault();
    if (number.length != 6) {
      alert("Number length must be of 6 digit");
    } else {
      setLoad(true);
      const date = new Date(
        document.getElementById("datetime").value
      ).toISOString();
      await fetch(
        `https://api.bestselalu.com/generate?uid=${uid}&number=${number}&date=${date}`,
        {
          method: "GET",
          headers: {
            "api-key": process.env.REACT_APP_API_KEY,
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Request failed.");
          }
          return response.json();
        })
        .then((data) => {
          setLoad(false);
          if (data.message == "generated") {
            setData(data.data);
            setCodeId(data.Id);
            setIsdone(true);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const handleDescription = async (event) => {
    event.preventDefault();
    if (number.length == 0) {
      alert("Write Something in description");
    } else {
      setDesLoad(true);
      const desc = document.getElementById("desc").value;
      await fetch(
        `https://api.bestselalu.com/addDescription?codeId=${codeId}&desc=${desc}`,
        {
          method: "GET",
          headers: {
            "api-key": process.env.REACT_APP_API_KEY,
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Request failed.");
          }
          return response.json();
        })
        .then((data) => {
          setDesLoad(false);
          if (data.message == "added") {
            document.getElementById("desc").value = "";
            alert("Description Added");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const handleNumber = (event) => {
    setNumber(event.target.value);
  };

  const handleChange = (event) => {
    setDate(event.target.value);
  };

  return (
    <div className="homepage">
      <div style={{ overflowY: "auto" }}>
        <div>
          <center>
            <img
              src={`https://api.bestselalu.com/images/${logo}`}
              alt=""
              style={{ width: "300px", height: "200px" }}
            />
            <h4
              style={{
                color: "white",
                border: "3px solid white",
                width: "300px",
                textAlign: "center",
                padding: "5px",
                fontWeight: "bold",
              }}
            >
              {name}
            </h4>
            {!isdone ? (
              <form onSubmit={Generate}>
                <Input
                  type="number"
                  value={number}
                  onChange={handleNumber}
                  style={{
                    width: "300px",
                    border: "3px solid white",
                  }}
                  required
                />
                <Input
                  type="datetime-local"
                  id="datetime"
                  style={{
                    width: "300px",
                    border: "3px solid white",
                  }}
                  required
                />
                <MDBBtn
                  style={{
                    width: "300px",
                    backgroundColor: "#D3D3D3",
                    color: "black",
                    border: "1px solid white",
                    marginTop: "-20px",
                  }}
                >
                  {load ? (
                    <MDBSpinner style={{ color: "white" }}></MDBSpinner>
                  ) : (
                    <span>Generate</span>
                  )}
                </MDBBtn>
              </form>
            ) : null}
            {isdone ? (
              <div style={{ marginTop: "10px" }}>
                <h5 style={{ color: "white" }}>ANGKA MAIN</h5>
                <p style={{ color: "yellow", fontSize: "18px" }}>{number}</p>
                <h5 style={{ color: "white" }}>TOP 4D (BB)</h5>
                <p style={{ color: "yellow", fontSize: "18px" }}>
                  {data.fourD}
                </p>
                <h5 style={{ color: "white" }}>TOP 3D (BB)</h5>
                <p style={{ color: "yellow", fontSize: "18px" }}>
                  {data.threeD}
                </p>
                <h5 style={{ color: "white" }}>TOP 2D (BB)</h5>
                <p style={{ color: "yellow", fontSize: "18px" }}>{data.twoD}</p>
                <h5 style={{ color: "white" }}>COLOK BEBAS</h5>
                <p style={{ color: "yellow", fontSize: "18px" }}>
                  {data.colorbean}
                </p>
                <h5 style={{ color: "white" }}>COLOK 2D</h5>
                <p style={{ color: "yellow", fontSize: "18px" }}>
                  {data.colok2D}
                </p>
                <h5 style={{ color: "white" }}>SHIO JITU</h5>
                <p style={{ color: "yellow", fontSize: "18px" }}>
                  {data.shioJitu}
                </p>
                <h5 style={{ color: "white" }}>Dated</h5>
                <p style={{ color: "yellow", fontSize: "18px" }}>
                  {new Date(data.Dated).toLocaleString()}
                </p>
                <form onSubmit={handleDescription}>
                  <Input
                    type="textarea"
                    id="desc"
                    placeholder="Description"
                    style={{
                      width: "300px",
                      border: "3px solid white",
                    }}
                    required
                  />
                  <MDBBtn
                    style={{
                      width: "300px",
                      backgroundColor: "#D3D3D3",
                      color: "black",
                      border: "1px solid white",
                      marginTop: "-20px",
                      marginBottom: "20px",
                    }}
                  >
                    {desload ? (
                      <MDBSpinner style={{ color: "white" }}></MDBSpinner>
                    ) : (
                      <span>Add</span>
                    )}
                  </MDBBtn>
                </form>
              </div>
            ) : null}
          </center>

          <div
            style={{
              position: "fixed",
              top: "20px",
              right: "20px",
              zIndex: 9999,
            }}
          >
            <Link to="/dashboard">
              <MDBBtn
                style={{
                  border: "2px solid white",
                  backgroundColor: "#D3D3D3",
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
              >
                BACK
              </MDBBtn>
            </Link>
          </div>
          <div
            style={{
              position: "fixed",
              top: "20px",
              right: "20px",
              zIndex: 9999,
            }}
          >
            <Link to="/dashboard">
              <MDBBtn
                style={{
                  border: "2px solid white",
                  backgroundColor: "#D3D3D3",
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
              >
                BACK
              </MDBBtn>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
