import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

export default function Dashboard() {
  const [admins, setAdmins] = useState([]);

  useEffect(() => {
    Getadmins();
  }, []);

  const Getadmins = async () => {
    await fetch(`https://api.bestselalu.com/getAdmins`, {
      method: "GET",
      headers: {
        "api-key": process.env.REACT_APP_API_KEY,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Request failed.");
        }
        return response.json();
      })
      .then((data) => {
        setAdmins(data.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
      }}
      className="homepage"
    >
      <div>
        <h1
          style={{
            fontWeight: "bold",
            backgroundColor: "#D3D3D3",
            color: "black",
            border: "3px solid white",
            padding: "15px",
            width: "220px",
            fontSize: "25px",
            textAlign: "center",
          }}
        >
          DASHBOARD
        </h1>
      </div>
      {admins.map((admin, index) => (
        <div>
          <div>
            <Link to={`/dashboard`}>
              <h4
                style={{
                  backgroundColor: "#D3D3D3",
                  color: "black",
                  fontWeight: "bold",
                  border: "3px solid white",
                  padding: "15px",
                  width: "220px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                {admin.admin}
              </h4>
            </Link>
          </div>
        </div>
      ))}
      <div
        className="button-container"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <div className="circle-button" style={{ marginRight: "40px" }}>
          <center>
            <Link to="/settings">
              <i
                className="fas fa-cog"
                style={{ fontSize: "30px", color: "white" }}
              ></i>
            </Link>
          </center>
          <p style={{ color: "white" }}>Admin Settings</p>
        </div>
        <div className="circle-button">
          <center>
            {" "}
            <Link to="/add-admin">
              <i
                className="fas fa-plus"
                style={{ fontSize: "30px", color: "white" }}
              ></i>
            </Link>
          </center>
          <p style={{ color: "white" }}>Add Admin</p>
        </div>
      </div>
    </div>
  );
}
