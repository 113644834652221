import React, { useEffect, useState } from "react";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { MDBBtn, MDBSpinner } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

export default function Addadmin() {
  const [load, setLoad] = useState("");

  const Addadmin = async (event) => {
    event.preventDefault();
    setLoad(true);
    const adminname = document.getElementById("name").value;
    const Apassword = document.getElementById("Apassword").value;
    const Ypassword = document.getElementById("Ypassword").value;
    await fetch(
      `https://api.bestselalu.com/addadmin?name=${adminname}&password=${Apassword}&ypassword=${Ypassword}`,
      {
        method: "GET",
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Request failed.");
        }
        return response.json();
      })
      .then((data) => {
        setLoad(false);
        if (data.message == "added") {
          document.getElementById("adminform").reset();
          document.getElementById("error").innerHTML = "Admin Added";
          document.getElementById("error").style.display = "block";
          document.getElementById("error").style.color = "green";
        } else if (data.message == "invalid") {
          document.getElementById("adminform").reset();
          document.getElementById("error").innerHTML =
            "YOUR PASSWORD IS INCORRECT";
          document.getElementById("error").style.display = "block";
          document.getElementById("error").style.color = "red";
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
      }}
      className="homepage"
    >
      <div>
        <h1
          style={{
            color: "white",
            fontWeight: "bold",
            padding: "15px",
            fontSize: "25px",
            textAlign: "center",
          }}
        >
          ADD ADMIN
        </h1>
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <form onSubmit={Addadmin} id="adminform">
            <div style={{ alignItems: "center", justifyContent: "center" }}>
              <div>
                <label htmlFor="" style={{ color: "white" }}>
                  Admin Name
                </label>
                <div>
                  <input
                    type="text"
                    id="name"
                    style={{
                      backgroundColor: "#D3D3D3",
                      color: "black",
                      border: "4px solid white",
                      width: "300px",
                    }}
                  />
                </div>
                <label htmlFor="" style={{ color: "white" }}>
                  ADMIN PASSWORD
                </label>
                <div>
                  <input
                    type="password"
                    id="Apassword"
                    style={{
                      backgroundColor: "#D3D3D3",
                      color: "black",
                      border: "4px solid white",
                      width: "300px",
                    }}
                  />
                </div>
                <label htmlFor="" style={{ color: "white" }}>
                  YOUR PASSWORD
                </label>
                <div>
                  <input
                    type="password"
                    id="Ypassword"
                    style={{
                      backgroundColor: "#D3D3D3",
                      color: "black",
                      border: "4px solid white",
                      width: "300px",
                    }}
                  />
                  <span id="error"></span>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "10px" }}>
              <MDBBtn
                style={{
                  width: "300px",
                  backgroundColor: "#D3D3D3",
                  color: "black",
                  border: "1px solid white",
                }}
              >
                {load ? (
                  <MDBSpinner style={{ color: "white" }}></MDBSpinner>
                ) : (
                  <span>Add</span>
                )}
              </MDBBtn>
            </div>
          </form>
          <div
            style={{
              position: "fixed",
              top: "20px",
              right: "20px",
              zIndex: 9999,
            }}
          >
            <Link to="/">
              <MDBBtn
                style={{
                  border: "2px solid white",
                  backgroundColor: "#D3D3D3",
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
              >
                BACK
              </MDBBtn>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
