import './App.css';
import Dashboard from './Components/Admins/dashboard';
import Login from './Components/Admins/login';
import Admin from './Components/Admins/admin';
import PrivateRoute from './Components/private';
import Main from './Components/Admins/main';
import Addlogo from './Components/Admins/addlogo';
import View from './Components/Users/view';
import Addwhatsapp from './Components/Admins/addwhatsapp';
import Sites from './Components/Admins/sites';
import LinkSites from './Components/Users/sites';
import Addadmin from './Components/Admins/addadmin';
import Setting from './Components/Admins/setting';
import AddName from './Components/Admins/addname';
import AddLinks from './Components/Admins/addLinks';
import { Switch,Route } from 'react-router-dom';

const App=()=>{
    return (
        <Switch>
            <Route exact path="/" component={Login}/>
            <Route exact path="/main-dashboard" component={Dashboard}/>
            <Route exact path="/Viewer/:admin/:code" component={View}/>
            <PrivateRoute path="/dashboard" component={Admin}/>
            <PrivateRoute path="/generate-number" component={Main}/>
            <PrivateRoute path="/add-logo" component={Addlogo}/>
            <PrivateRoute path="/add-whatsapp" component={Addwhatsapp}/>
            <PrivateRoute path="/link-sites/:id" component={LinkSites}/>
            <PrivateRoute path="/my-sites" component={Sites}/>
            <Route path="/add-admin" component={Addadmin}/>
            <Route path="/settings" component={Setting}/>
            <Route path="/add-name" component={AddName}/>
            <Route path="/custom-link" component={AddLinks}/>
        </Switch>
    )
}
export default App; 