import React, { useEffect, useState } from "react";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { MDBBtn, MDBSpinner } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";

export default function Setting() {
  const [load, setLoad] = useState(false);

  const Addadmin = async (event) => {
    event.preventDefault();
    setLoad(true);
    const Cpassword = document.getElementById("Cpassword").value;
    const Npassword = document.getElementById("Npassword").value;
    await fetch(
      `https://api.bestselalu.com/updateadmin?Cpassword=${Cpassword}&Npassword=${Npassword}`,
      {
        method: "GET",
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Request failed.");
        }
        return response.json();
      })
      .then((data) => {
        setLoad(false);
        if (data.message == "added") {
          document.getElementById("adminform").reset();
          document.getElementById("error").innerHTML = "UPDATED";
          document.getElementById("error").style.display = "block";
          document.getElementById("error").style.color = "green";
        } else if (data.message == "invalid") {
          document.getElementById("error").innerHTML =
            "YOUR PASSWORD IS INCORRECT";
          document.getElementById("error").style.display = "block";
          document.getElementById("error").style.color = "red";
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
      }}
      className="homepage"
    >
      <div>
        <h1
          style={{
            color: "white",
            fontWeight: "bold",
            padding: "15px",
            fontSize: "25px",
            textAlign: "center",
          }}
        >
          ADD ADMIN
        </h1>
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <form onSubmit={Addadmin} id="adminform">
            <div style={{ alignItems: "center", justifyContent: "center" }}>
              <div>
                <label htmlFor="" style={{ color: "white" }}>
                  CURRENT PASSWORD
                </label>
                <div>
                  <input
                    type="password"
                    id="Cpassword"
                    style={{
                      backgroundColor: "#D3D3D3",
                      color: "black",
                      border: "4px solid white",
                      width: "300px",
                    }}
                  />
                </div>
                <label htmlFor="" style={{ color: "white" }}>
                  NEW PASSWORD
                </label>
                <div>
                  <input
                    type="password"
                    id="Npassword"
                    style={{
                      backgroundColor: "#D3D3D3",
                      color: "black",
                      border: "4px solid white",
                      width: "300px",
                    }}
                  />
                </div>
              </div>
            </div>
            <div style={{ marginTop: "10px" }}>
              <MDBBtn
                style={{
                  width: "300px",
                  backgroundColor: "#D3D3D3",
                  color: "black",
                  border: "1px solid white",
                }}
              >
                {load ? (
                  <MDBSpinner style={{ color: "white" }}></MDBSpinner>
                ) : (
                  <span>UPDATE</span>
                )}
              </MDBBtn>
            </div>
          </form>
          <div
            style={{
              position: "fixed",
              top: "20px",
              right: "20px",
              zIndex: 9999,
            }}
          >
            <Link to="/">
              <MDBBtn
                style={{
                  border: "2px solid white",
                  backgroundColor: "#D3D3D3",
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
              >
                BACK
              </MDBBtn>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
