import React, { useEffect, useState } from "react";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { MDBBtn, MDBSpinner } from "mdb-react-ui-kit";
import { Link, useParams } from "react-router-dom";
import Cookies from "js-cookie";

export default function Login() {
  const [password, setPassword] = useState("");
  const [load, setLoad] = useState("");

  const { admin } = useParams();
  const handleLogin = async (event) => {
    event.preventDefault();
    setLoad(true);
    await fetch(`https://api.bestselalu.com/login?password=${password}`, {
      method: "GET",
      headers: {
        "api-key": process.env.REACT_APP_API_KEY,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Request failed.");
        }
        return response.json();
      })
      .then((data) => {
        setLoad(false);
        if (data.message == "invalid") {
          document.getElementById("error").innerHTML = "INVALID LOGIN DETAILS";
          document.getElementById("error").style.display = "block";
          document.getElementById("error").style.color = "red";
        } else if (data.message == "seshad") {
          Cookies.set("sesh_a", data.sesh_u, { expires: 2 });
          Cookies.set("sesh_uid", data.sesh_uid, { expires: 2 });
          window.location.href = "/main-dashboard";
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
      }}
      className="homepage"
    >
      <div>
        <h1
          style={{
            color: "white",
            fontWeight: "bold",
            padding: "15px",
            fontSize: "25px",
            textAlign: "center",
          }}
        >
          ADMIN LOGIN
        </h1>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <label
            htmlFor=""
            style={{
              color: "white",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            ENTER PASSWORD
          </label>
          <form onSubmit={handleLogin}>
            <div>
              <input
                type="password"
                style={{
                  color: "black",
                  border: "4px solid white",
                  width: "300px",
                }}
                value={password}
                onChange={handlePassword}
                placeholder="********"
              />
              <span id="error"></span>
            </div>
            <div style={{ marginTop: "10px" }}>
              <MDBBtn
                style={{
                  width: "300px",
                  backgroundColor: "#D3D3D3",
                  color: "black",
                  border: "1px solid white",
                }}
              >
                {load ? (
                  <MDBSpinner style={{ color: "white" }}></MDBSpinner>
                ) : (
                  <span>Login</span>
                )}
              </MDBBtn>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
