import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { MDBBtn } from "mdb-react-ui-kit";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

export default function Admin() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
      }}
      className="homepage"
    >
      <div>
        <h1
          style={{
            backgroundColor: "#D3D3D3",
            color: "black",
            fontWeight: "bold",
            border: "3px solid white",
            padding: "15px",
            width: "300px",
            fontSize: "25px",
            textAlign: "center",
          }}
        >
          ADMIN
        </h1>
      </div>
      <div>
        <Link to="/generate-number">
          <h4
            style={{
              backgroundColor: "#D3D3D3",
              color: "black",
              fontWeight: "bold",
              border: "3px solid white",
              padding: "15px",
              width: "300px",
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            GENERATE THE NUMBER
          </h4>
        </Link>
      </div>
      <div>
        <Link to="/add-logo">
          <h4
            style={{
              backgroundColor: "#D3D3D3",
              color: "black",
              fontWeight: "bold",
              border: "3px solid white",
              padding: "15px",
              width: "300px",
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            ADDING LOGO
          </h4>
        </Link>
      </div>
      <div>
        <Link to="/add-name">
          <h4
            style={{
              backgroundColor: "#D3D3D3",
              color: "black",
              fontWeight: "bold",
              border: "3px solid white",
              padding: "15px",
              width: "300px",
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            ADDING NAME
          </h4>
        </Link>
      </div>
      <div>
        <Link to="/add-whatsapp">
          <h4
            style={{
              backgroundColor: "#D3D3D3",
              color: "black",
              fontWeight: "bold",
              border: "3px solid white",
              padding: "15px",
              width: "300px",
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            ADDING WHATSAPP NUMBER
          </h4>
        </Link>
      </div>
      <div>
        <Link to="/my-sites">
          <h4
            style={{
              backgroundColor: "#D3D3D3",
              color: "black",
              fontWeight: "bold",
              border: "3px solid white",
              padding: "15px",
              width: "300px",
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            LINK SITES
          </h4>
        </Link>
      </div>
      <div>
        <Link to="/custom-link">
          <h4
            style={{
              backgroundColor: "#D3D3D3",
              color: "black",
              fontWeight: "bold",
              border: "3px solid white",
              padding: "15px",
              width: "300px",
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            ADD CUSTOM LINK
          </h4>
        </Link>
      </div>
      <div
        style={{
          position: "fixed",
          top: "20px",
          right: "20px",
          zIndex: 9999,
        }}
      >
        <Link to="/">
          <MDBBtn
            style={{
              border: "2px solid white",
              backgroundColor: "black",
              color: "white",
              fontWeight: "bold",
              fontSize: "15px",
            }}
          >
            BACK
          </MDBBtn>
        </Link>
      </div>
    </div>
  );
}
