import React, { useEffect, useState } from "react";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link } from "react-router-dom";
import { MDBBtn } from "mdb-react-ui-kit";
import Cookies from "js-cookie";

export default function Sites() {
  const [data, setData] = useState([]);
  const [uid, setUid] = useState("");

  useEffect(() => {
    const sesh = Cookies.get("sesh_uid");
    if (sesh != null) {
      const cleanedHex = sesh.replace(/\s/g, "");
      const hexArray = new Uint8Array(
        cleanedHex.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
      );
      const decoder = new TextDecoder("utf-8");
      setUid(decoder.decode(hexArray));
      GetSites(decoder.decode(hexArray));
    }
  }, []);

  const GetSites = async (uid) => {
    await fetch(`https://api.bestselalu.com/getSites?uid=${uid}`, {
      method: "GET",
      headers: {
        "api-key": process.env.REACT_APP_API_KEY,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Request failed.");
        }
        return response.json();
      })
      .then((data) => {
        setData(data.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleDelete = async (Id) => {
    await fetch(`https://api.bestselalu.com/deletesite?Id=${Id}`, {
      method: "GET",
      headers: {
        "api-key": process.env.REACT_APP_API_KEY,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Request failed.");
        }
        return response.json();
      })
      .then((data) => {
        if (data.message == "deleted") {
          GetSites(uid);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
      }}
      className="homepage"
    >
      <div>
        <h1
          style={{
            color: "white",
            fontWeight: "bold",
            padding: "15px",
            fontSize: "25px",
            textAlign: "center",
            border: "3px solid white",
          }}
        >
          MY SITES
        </h1>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {data.map((item, index) => (
            <div>
              <a
                href={`https://prediksiviral.com/Viewer/${item.adminId}/${item.codeId}`}
                style={{ color: "white" }}
              >
                SITE {index + 1} (Id: {item.Id})
              </a>
              <i
                className="fas fa-trash"
                onClick={() => {
                  if (window.confirm("Are you sure you want to delete?")) {
                    handleDelete(item.Id);
                  }
                }}
                style={{ marginLeft: "10px", color: "red", cursor: "pointer" }}
              ></i>
            </div>
          ))}
        </div>
        <div
          style={{
            position: "fixed",
            top: "20px",
            right: "20px",
            zIndex: 9999,
          }}
        >
          <Link to="/dashboard">
            <MDBBtn
              style={{
                border: "2px solid white",
                backgroundColor: "black",
                color: "white",
                fontWeight: "bold",
                fontSize: "15px",
              }}
            >
              BACK
            </MDBBtn>
          </Link>
        </div>
      </div>
    </div>
  );
}
