import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Cookies from "js-cookie";

export default function LinkSites() {
  const { id } = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    document.body.style.backgroundColor = "black";
    const sesh = Cookies.get("sesh_uid");
    if (sesh != null) {
      const cleanedHex = sesh.replace(/\s/g, "");
      const hexArray = new Uint8Array(
        cleanedHex.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
      );
      const decoder = new TextDecoder("utf-8");
      GetSites(decoder.decode(hexArray));
    }
  }, []);

  const GetSites = async (uid) => {
    await fetch(`https://api.bestselalu.com/getSites?uid=${uid}`, {
      method: "GET",
      headers: {
        "api-key": process.env.REACT_APP_API_KEY,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Request failed.");
        }
        return response.json();
      })
      .then((data) => {
        setData(data.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <div>
        <h1
          style={{
            color: "white",
            fontWeight: "bold",
            padding: "15px",
            fontSize: "25px",
            textAlign: "center",
            border: "3px solid white",
          }}
        >
          LINK SITES
        </h1>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {data.map((item, index) => (
            <a
              href={`https://prediksiviral.com/Viewer?adminId=${item.adminId}&codeId=${item.codeId}`}
            >
              SITE {index + 1}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}
