import React, { useEffect, useState } from "react";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link } from "react-router-dom";
import { MDBBtn, MDBSpinner } from "mdb-react-ui-kit";
import Cookies from "js-cookie";

export default function AddName() {
  const [uid, setUid] = useState("");
  const [load, setLoad] = useState("");

  useEffect(() => {
    const sesh = Cookies.get("sesh_uid");
    if (sesh != null) {
      const cleanedHex = sesh.replace(/\s/g, "");
      const hexArray = new Uint8Array(
        cleanedHex.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
      );
      const decoder = new TextDecoder("utf-8");
      setUid(decoder.decode(hexArray));
    }
  }, []);

  const handleName = async (event) => {
    event.preventDefault();
    setLoad(true);
    const name = document.getElementById("name").value;
    await fetch(`https://api.bestselalu.com/addname?uid=${uid}&name=${name}`, {
      method: "GET",
      headers: {
        "api-key": process.env.REACT_APP_API_KEY,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Request failed.");
        }
        return response.json();
      })
      .then((data) => {
        setLoad(false);
        if (data && data.message === "added") {
          document.getElementById("error").innerHTML = "Name Added";
          document.getElementById("error").style.display = "block";
          document.getElementById("error").style.color = "green";
        } else {
          console.log(data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
      }}
      className="homepage"
    >
      <div>
        <h1
          style={{
            color: "white",
            fontWeight: "bold",
            padding: "15px",
            fontSize: "25px",
            textAlign: "center",
          }}
        >
          ADD NAME
        </h1>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <form onSubmit={handleName}>
            <div>
              <input
                type="text"
                id="name"
                style={{
                  backgroundColor: "#D3D3D3",
                  color: "black",
                  border: "4px solid white",
                  width: "300px",
                }}
              />
              <span id="error"></span>
            </div>
            <div style={{ marginTop: "10px" }}>
              <MDBBtn
                style={{
                  width: "300px",
                  backgroundColor: "#D3D3D3",
                  color: "black",
                  border: "1px solid white",
                }}
              >
                {load ? (
                  <MDBSpinner style={{ color: "white" }}></MDBSpinner>
                ) : (
                  <span>Add</span>
                )}
              </MDBBtn>
            </div>
          </form>
          <div
            style={{
              position: "fixed",
              top: "20px",
              right: "20px",
              zIndex: 9999,
            }}
          >
            <Link to="/dashboard">
              <MDBBtn
                style={{
                  border: "2px solid white",
                  backgroundColor: "#D3D3D3",
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
              >
                BACK
              </MDBBtn>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
